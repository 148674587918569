window.HMA = window.HMA || {};

// The debounce function receives our function as a parameter
const debounce = (fn) => {
    // This holds the requestAnimationFrame reference, so we can cancel it if we wish
    let frame;

    // The debounce function returns a new function that can receive a variable number of arguments
    return (...params) => {
        // If the frame variable has been defined, clear it now, and queue for next frame
        if (frame) {
            cancelAnimationFrame(frame);
        }

        // Queue our function call for the next frame
        frame = requestAnimationFrame(() => {
            // Call our function and pass any params we received
            fn(...params);
        });
    };
};

function ready(fn) {
    if (
        document.attachEvent
            ? document.readyState === "complete"
            : document.readyState !== "loading"
    ) {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

HMA.core = (function () {
    "use strict";

    const initTabs = () => {
        const tabs = document.querySelector("#js-tabs");

        if (!tabs) return;

        // Get all buttons
        const buttons = Array.prototype.slice.call(
            tabs.querySelectorAll(".tabs__button")
        );
        const tabBodies = Array.prototype.slice.call(
            tabs.querySelectorAll(".tabs__tab")
        );

        buttons.forEach((btn) => {
            // Hide tabs that aren't selected
            if (btn.getAttribute("aria-selected") === "false") {
                const targetTab = tabs.querySelector(
                    `#${btn.getAttribute("aria-controls")}`
                );
                if (targetTab) {
                    targetTab.setAttribute("hidden", true);
                }
            }

            // Add click listener to buttons to control tabs
            btn.addEventListener("click", function (ev) {
                if (btn.getAttribute("aria-selected") === "true") return;

                buttons.forEach((button) => {
                    if (button !== btn) {
                        button.setAttribute("aria-selected", false);
                    } else {
                        button.setAttribute("aria-selected", true);
                    }
                });
                const targetTab = tabs.querySelector(
                    `#${btn.getAttribute("aria-controls")}`
                );
                tabBodies.forEach((tab) => {
                    if (tab !== targetTab) {
                        tab.setAttribute("hidden", true);
                    } else {
                        tab.removeAttribute("hidden");
                    }
                });
            });
        });
    };

    const initMaps = () => {
        const mapElement = document.querySelector(".acf-map");

        if (!mapElement) return;

        const marker = mapElement.querySelector(".marker");

        const args = {
            zoom: 12,
            center: new google.maps.LatLng(
                mapElement.dataset.lat,
                mapElement.dataset.lng
            ),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
        };

        const map = new google.maps.Map(mapElement, args);

        const gMarker = new google.maps.Marker({
            position: new google.maps.LatLng(
                mapElement.dataset.lat,
                mapElement.dataset.lng
            ),
            map: map,
        });

        // Make info window if the marker has innerHTML
        if (marker.innerHTML) {
            const infoWindow = new google.maps.InfoWindow({
                content: marker.innerHTML,
            });

            // Open infoWindow when marker is clicked
            gMarker.addListener("click", function () {
                infoWindow.open(map, gMarker);
            });
        }

        return map;
    };

    const initQuotesSliders = () => {
        const quoteBlocks = Array.prototype.slice.call(
            document.querySelectorAll(".flickity-testimonials")
        );

        if (!quoteBlocks) return;

        quoteBlocks.forEach((block) => {
            new Flickity(block, {
                pageDots: true,
                prevNextButtons: false,
                imagesLoaded: true,
                autoPlay: 8000,
            });
        });
    };

    const initStatsSliders = () => {
        const statsSliders = Array.prototype.slice.call(
            document.querySelectorAll(".flickity-stats")
        );

        if (!statsSliders) return;

        statsSliders.forEach((block) => {
            new Flickity(block, {
                pageDots: true,
                prevNextButtons: true,
                imagesLoaded: false,
                autoPlay: 8000,
                wrapAround: true,
                // adaptiveHeight: true,
            });
        });
    };

    const initGallerySliders = () => {
        const gallerySliders = Array.prototype.slice.call(
            document.querySelectorAll(".flickity-gallery")
        );

        if (!gallerySliders) return;

        gallerySliders.forEach((block) => {
            const flkty = new Flickity(block, {
                pageDots: true,
                prevNextButtons: false,
                imagesLoaded: false,
                autoPlay: 4000,
                wrapAround: true,
                draggable: false,
                // adaptiveHeight: true,
            });

            // The gallery has videos, so if the user clicks on an item that is not an image (like the video), stop autoplay
            flkty.on("pointerDown", function (event, pointer) {
                // console.log(event.target.nodeName, event.target.nodeName == 'IMG');
                if (event.target.nodeName != "IMG") {
                    flkty.stopPlayer();
                }
            });
        });
    };

    const initHeroSlider = () => {
        const heroSlider = document.querySelector("#js-hero-carousel");

        if (!heroSlider) return;

        new Flickity(heroSlider, {
            pageDots: true,
            prevNextButtons: false,
            imagesLoaded: true,
            autoPlay: 4000,
        });
    };

    const initNav = () => {
        const subnavToggles = Array.prototype.slice.call(
            document.querySelectorAll(".site-nav__sublist-toggle")
        );

        subnavToggles.forEach((toggle) => {
            toggle.addEventListener("click", function () {
                let expanded = this.getAttribute("aria-expanded") == "true";
                this.setAttribute("aria-expanded", expanded ? "false" : "true");
            });
        });

        const closeButton = document.querySelector(".site-nav__close");

        if (closeButton) {
            closeButton.addEventListener("click", function () {
                document
                    .querySelector("body")
                    .classList.remove("site-nav--open");
            });
        }

        const openButton = document.querySelector(".header__open");

        if (openButton) {
            openButton.addEventListener("click", function () {
                document.querySelector("body").classList.add("site-nav--open");
            });
        }
    };

    const initSitemap = () => {
        const sitemapSection = document.querySelector("#js-sitemap");
        if (!sitemapSection) return;

        const sitemap = sitemapSection.querySelector(".site-map__map");

        sitemap.addEventListener("click", function (e) {
            e.stopPropagation();

            // Hide open article if there is one
            const openArticle = sitemapSection.querySelector(
                '.site-map__article[aria-expanded="true"]'
            );
            if (openArticle) openArticle.setAttribute("aria-expanded", false);

            const activeElement = sitemapSection.querySelector(".active");
            if (activeElement) activeElement.classList.remove("active");

            const target = e.target;

            // If target is a .site-map__element , open corresponding article
            if (
                target.classList.contains("site-map__element") ||
                target.classList.contains("site-map__icon")
            ) {
                const targetId = target.getAttribute("id");
                console.log(targetId);
                const targetArticle = sitemapSection.querySelector(
                    `#article_${targetId}`
                );
                if (targetArticle)
                    targetArticle.setAttribute("aria-expanded", true);

                // Add or remove active class for sitemapelement
                target.classList.add("active");
            } else if (target.parentNode.classList.contains("site-map__icon")) {
                const targetId = target.parentNode.getAttribute("id");
                console.log(targetId);
                const targetArticle = sitemapSection.querySelector(
                    `#article_${targetId}`
                );
                if (targetArticle)
                    targetArticle.setAttribute("aria-expanded", true);

                // Add or remove active class for sitemapelement
                target.classList.add("active");
            }
        });

        // Add event listeners to article titles (for below tablet width - ensured with css) to act as accordions
        const articleTitles = Array.prototype.slice.call(
            document.querySelectorAll(".site-map__article>h3")
        );
        if (articleTitles) {
            articleTitles.forEach((title) => {
                title.addEventListener("click", function () {
                    const parent = this.parentElement;
                    let expanded =
                        parent.getAttribute("aria-expanded") == "true";
                    parent.setAttribute(
                        "aria-expanded",
                        expanded ? "false" : "true"
                    );
                });
            });
        }
    };

    const initGallery = () => {
        const gallery = document.querySelector(".js-gallery");
        const galleryOverlay = document.querySelector(".gallery-overlay");

        if (!gallery || !galleryOverlay) return;
        const galleryOverlayThumbTrack = galleryOverlay.querySelector(
            ".gallery-overlay__thumb-track"
        );
        const galleryFeature = galleryOverlay.querySelector(
            ".gallery-overlay__feature"
        );
        const galleryCaption = galleryOverlay.querySelector(
            ".gallery-overlay__captions"
        );
        const galleryProgress = galleryOverlay.querySelector(
            ".gallery-overlay__progress>span"
        );

        function updateGalleryContent(index, src, caption, videoId) {
            let featureImage = document.createElement("img");
            featureImage.src = src;
            featureImage.width = "1280";
            featureImage.height = "720";
            if (videoId) {
                featureImage.style.cursor = "pointer";
                featureImage.addEventListener("click", function (e) {
                    // Replace image with wistia video...
                    galleryFeature.innerHTML = `<div class="video"><div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/${videoId}?seo=false&videoFoam=true" title="Video" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div>
                    <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script></div>`;
                });
            }
            galleryFeature.innerHTML = "";
            galleryFeature.appendChild(featureImage);
            galleryCaption.innerHTML = `<p>${caption ? caption : ""}</p>`;
            galleryProgress.innerText = index + 1;
        }

        function destroyOverlay(slider) {
            if (slider) slider.destroy(); // Destroy carousel
            galleryOverlay
                .querySelector(".gallery-overlay__close")
                .removeEventListener("click", destroyOverlay); // Reset ev listener on the button
            galleryOverlay.setAttribute("hidden", true); // Hide overlay
        }

        // Open overlay when gallery item is clicked. Initialise flickity with initial cell
        gallery.addEventListener("click", function (ev) {
            const target = ev.target;
            if (target.classList.contains("gallery__thumbnail")) {
                let targetIndex = parseInt(target.dataset.index, 10);
                let captionContent = target.dataset.caption;
                let fullSizeSrc = target.dataset.fullsize;
                let videoId = target.dataset.videoid;
                updateGalleryContent(
                    targetIndex,
                    fullSizeSrc,
                    captionContent,
                    videoId
                );

                galleryOverlay.removeAttribute("hidden");
                const thumbTrackSlider = new Flickity(
                    galleryOverlayThumbTrack,
                    {
                        pageDots: false,
                        prevNextButtons: true,
                        autoPlay: false,
                        imagesLoaded: true,
                        cellAlign: "left",
                        initialIndex: targetIndex,
                    }
                );

                thumbTrackSlider.on("change", function (index) {
                    const selectedCell = thumbTrackSlider.cells[index].element;
                    if (!selectedCell) return;
                    let captionContent = selectedCell.dataset.caption;
                    let fullSizeSrc = selectedCell.dataset.fullsize;
                    let videoId = selectedCell.dataset.videoid;
                    updateGalleryContent(
                        index,
                        fullSizeSrc,
                        captionContent,
                        videoId
                    );
                });
                thumbTrackSlider.on(
                    "staticClick",
                    function (event, pointer, cellElement, cellIndex) {
                        if (!cellElement) return;
                        thumbTrackSlider.select(cellIndex);
                    }
                );

                // Focus new slider
                thumbTrackSlider.element.focus();

                galleryOverlay
                    .querySelector(".gallery-overlay__close")
                    .addEventListener("click", function () {
                        destroyOverlay(thumbTrackSlider);
                    });
                document.addEventListener("keyup", function (e) {
                    if (e.key === "Escape") destroyOverlay(thumbTrackSlider);
                });
            }
            ev.preventDefault();
        });
    };

    const initRecaptcha = () => {
        if (document.getElementById("get-in-touch-form") !== null) {
            document
                .getElementById("get-in-touch-form")
                .addEventListener("submit", function (ev) {
                    ev.preventDefault();
                    grecaptcha.execute();
                });
        }
        if (document.getElementById("landing-page-form") !== null) {
            document
                .getElementById("landing-page-form")
                .addEventListener("submit", function (ev) {
                    ev.preventDefault();
                    grecaptcha.execute();
                });
        }
        if (document.getElementById("gym-registration-form") !== null) {
            document
                .getElementById("gym-registration-form")
                .addEventListener("submit", function (ev) {
                    ev.preventDefault();
                    grecaptcha.execute();
                });
        }
        if (document.getElementById("recommend-a-friend-form") !== null) {
            document
                .getElementById("recommend-a-friend-form")
                .addEventListener("submit", function (ev) {
                    ev.preventDefault();
                    grecaptcha.execute();
                });
        }

        if (document.getElementById("alive-well-contact-us-form") !== null) {
            document
                .getElementById("alive-well-contact-us-form")
                .addEventListener("submit", function (ev) {
                    ev.preventDefault();
                    grecaptcha.execute();
                });
        }
        if (document.getElementById("newsletter-signup") !== null) {
            document
                .getElementById("newsletter-signup")
                .addEventListener("submit", function (ev) {
                    ev.preventDefault();
                    grecaptcha.execute();
                });
        }
        if (document.getElementById("cm_data_capture_dl") !== null) {
            document
                .getElementById("cm_data_capture_dl")
                .addEventListener("submit", function (ev) {
                    ev.preventDefault();
                    grecaptcha.execute();
                });
        }
        if (document.getElementById("mtaw_charter_form") !== null) {
            document
                .getElementById("mtaw_charter_form")
                .addEventListener("submit", function (ev) {
                    ev.preventDefault();
                    grecaptcha.execute();
                });
        }
        if (document.getElementById("book-meeting-room-form") !== null) {
            document
                .getElementById("book-meeting-room-form")
                .addEventListener("submit", function (ev) {
                    ev.preventDefault();
                    grecaptcha.execute();
                });
        }
    };

    const initTimeline = () => {
        const timeline = document.querySelector(".js-timeline");

        if (!timeline) return;

        const callback = (entries, observer) => {
            entries.forEach((entry) => {
                entry.target.setAttribute("data-visible", entry.isIntersecting);
            });
        };
        const options = {
            // root: document.querySelector("[data-scroll-root]"),
            rootMargin: "5000px 0px -30% 0px",
            threshold: 0.5,
        };

        const observer = new IntersectionObserver(callback, options);
        const targets = timeline.querySelectorAll(".timeline__item");
        targets.forEach((t) => observer.observe(t));
    };

    const initEventModals = () => {
        const eventModalTriggers = Array.prototype.slice.call(
            document.querySelectorAll(".js-event-open-details")
        );

        if (!eventModalTriggers) return;

        eventModalTriggers.forEach((trigger) => {
            const targetDialog = document.getElementById(
                trigger.getAttribute("aria-controls")
            );
            if (targetDialog) {
                trigger.addEventListener("click", () => targetDialog.showModal());
            }
        });
    };

    const init = function () {
        document.querySelector("html").classList.remove("no-js");
        document.querySelector("html").classList.add("js");

        initTabs();
        initMaps();
        initQuotesSliders();
        initGallerySliders();
        initHeroSlider();
        initStatsSliders();
        initNav();
        initSitemap();
        initGallery();
        initRecaptcha();
        initTimeline();
        initEventModals();
    };

    return {
        init: init,
    };
})();

ready(function () {
    HMA.core.init();
});
